var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.detailLoading,
          expression: "detailLoading"
        }
      ],
      staticClass: "detailList"
    },
    [
      _c(
        "div",
        { staticClass: "header detailList-header grid-header" },
        [
          _c(
            "div",
            [
              _c(
                "label",
                { staticStyle: { "font-size": "12px", "margin-right": "5px" } },
                [_vm._v("所属角色:")]
              ),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "small",
                    clearable: "",
                    filterable: "",
                    placeholder: "全部"
                  },
                  model: {
                    value: _vm.factors.function,
                    callback: function($$v) {
                      _vm.$set(_vm.factors, "function", $$v)
                    },
                    expression: "factors.function"
                  }
                },
                _vm._l(_vm.newFunctionDicList, function(item) {
                  return _c("el-option", {
                    key: item.roleTagId,
                    attrs: { label: item.roleTagName, value: item.roleTagName }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search__keyword" },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入关键字" },
                model: {
                  value: _vm.fakeContent,
                  callback: function($$v) {
                    _vm.fakeContent = $$v
                  },
                  expression: "fakeContent"
                }
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.factors.content = _vm.fakeContent
                    }
                  }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", plain: "" },
              on: { click: _vm.clearFilter }
            },
            [_vm._v("重置")]
          ),
          _c(
            "div",
            { staticClass: "export" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", disabled: _vm.exporting },
                  on: { click: _vm.exportExcel }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "detailList-table__body" },
        [
          _c(
            "el-table",
            {
              ref: "filterTable",
              staticClass: "is-grey",
              attrs: {
                "highlight-current-row": "",
                height: "100%",
                size: "medium",
                data: _vm.tableData
              },
              on: { "filter-change": _vm.filterSpecs }
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_c("question", { attrs: { ckpId: row.ckpId } })]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  "min-width": "100px",
                  label: "序号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200px",
                  label: "条文内容",
                  "header-align": "left",
                  align: "left",
                  prop: "content"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", {
                          staticStyle: { width: "100%", overflow: "hidden" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.xss(row.content.replaceAll("\n", "<br>"))
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  "show-overflow-tooltip": "",
                  label: "所属角色",
                  align: "center",
                  prop: "functionName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  "show-overflow-tooltip": "",
                  label: "有无风险内容",
                  align: "center",
                  prop: "risk"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  "show-overflow-tooltip": "",
                  label: "问题数量",
                  align: "center",
                  prop: "problemCount"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  label: "模块",
                  align: "center",
                  prop: "moduleName"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "50px",
                  label: "部位",
                  align: "center",
                  prop: "positionName"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }