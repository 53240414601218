var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.questionLoading,
          expression: " questionLoading"
        }
      ],
      staticClass: "que-detail__wrapper"
    },
    [
      _c(
        "div",
        { staticClass: "left panel" },
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticClass: "is-grey",
              attrs: {
                "highlight-current-row": "",
                height: "650",
                size: "medium",
                data: _vm.tableData,
                "current-row-key": "problemId"
              },
              on: { "row-click": _vm.rowClick }
            },
            [
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "问题状态",
                  align: "center",
                  prop: "problemStatus"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                class: [
                                  "queStatus",
                                  _vm.getqueStatusTheme(row.problemStatus)
                                ]
                              },
                              [_vm._v(_vm._s(row.problemStatus))]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "专业",
                  align: "center",
                  prop: "problemSpec"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "问题类型",
                  align: "center",
                  prop: "problemType"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "修改方",
                  align: "center",
                  prop: "problemModify"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "审查人",
                  align: "center",
                  prop: "problemChecker"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "创建时间",
                  align: "center",
                  prop: "problemCreatTime"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "完成时间",
                  align: "center",
                  prop: "problemFinishTime"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "完成状态",
                  align: "center",
                  prop: "problemStatus"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "span",
                          { style: _vm.getOverDue(row.isOverDue).theme },
                          [_vm._v(_vm._s(_vm.getOverDue(row.isOverDue).text))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "问题来源",
                  align: "center",
                  prop: "problemSource"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "案例来源",
                  align: "center",
                  prop: "ckpSource"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pageSwitcher" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: _vm.page.layout,
                  "page-size": _vm.page.pageSize,
                  "current-page": _vm.page.pageNo,
                  total: _vm.page.totalCount
                },
                on: { "current-change": _vm.handlePageSwitch }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right panel" },
        [
          _c("detail", {
            attrs: {
              questionId: _vm.currentQuestionId,
              questionType: _vm.currentQuestionType
            },
            on: { refresh: _vm.refreshQuestions }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }