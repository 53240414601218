var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "问题详情", name: "detail" } }),
          _c("el-tab-pane", { attrs: { label: "回复", name: "response" } }),
          _c("el-tab-pane", {
            attrs: { label: "审核记录", name: "auditRecord" }
          }),
          !_vm.questionHasBeenSelected
            ? _c("div", { staticClass: "empty-panel" }, [
                _c("img", { attrs: { src: require("@/assets/Empty.png") } }),
                _c("span", [_vm._v("未选择问题")])
              ])
            : [
                _vm.activeName === "detail"
                  ? _c("issueDetail", {
                      key: _vm.questionId,
                      ref: "showDetail",
                      attrs: {
                        isNewList: true,
                        type: _vm.detailType,
                        id: _vm.questionId,
                        dicArray: _vm.dicData,
                        mountLoad: true
                      },
                      on: {
                        changeDetail: _vm.refreshDetail,
                        openBigImg: _vm.openImgDialog
                      }
                    })
                  : _vm._e(),
                _vm.activeName === "response"
                  ? _c("reply", {
                      key: _vm.questionId,
                      ref: "reply",
                      attrs: {
                        type: _vm.detailType,
                        id: _vm.questionId,
                        isNewList: true
                      },
                      on: { resetList: function() {} }
                    })
                  : _vm._e(),
                _vm.activeName === "auditRecord"
                  ? _c("record", {
                      key: _vm.questionId,
                      ref: "record",
                      attrs: {
                        type: _vm.detailType,
                        id: _vm.questionId,
                        isNewList: true
                      }
                    })
                  : _vm._e()
              ]
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          key: _vm.questionId,
          staticClass: "el-dialog--maia",
          attrs: {
            title: "问题详情",
            visible: _vm.dialogVisible,
            width: "870px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm.imgList.length > 0
            ? _c("div", { staticClass: "maia-content" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    staticClass: "viewImg",
                    attrs: { src: _vm.imgList[_vm.imgIndex], alt: "" }
                  }),
                  _c(
                    "div",
                    { staticClass: "img" },
                    _vm._l(_vm.imgList, function(img, index) {
                      return _c("img", {
                        key: index,
                        attrs: { src: img, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.amplificationView(index)
                          }
                        }
                      })
                    }),
                    0
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "el-form",
                      {
                        key: "detailData",
                        ref: "detailData",
                        attrs: {
                          model: _vm.detailData,
                          size: "mini",
                          "label-width": "95px"
                        }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "问题状态:" } }, [
                          _vm._v(_vm._s(_vm.detailData.queStatus))
                        ]),
                        _c("el-form-item", { attrs: { label: "问题描述:" } }, [
                          _vm._v(_vm._s(_vm.detailData.queDesc))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }