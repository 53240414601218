import { render, staticRenderFns } from "./question.vue?vue&type=template&id=9276c878&scoped=true&"
import script from "./question.vue?vue&type=script&lang=js&"
export * from "./question.vue?vue&type=script&lang=js&"
import style0 from "./question.vue?vue&type=style&index=0&id=9276c878&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9276c878",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\@company\\lzz-productcheck-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9276c878')) {
      api.createRecord('9276c878', component.options)
    } else {
      api.reload('9276c878', component.options)
    }
    module.hot.accept("./question.vue?vue&type=template&id=9276c878&scoped=true&", function () {
      api.rerender('9276c878', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mandatoryList/detailList/question.vue"
export default component.exports