import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";

function getFileNameByContentDisposition(contentDisposition) {
  if (!contentDisposition) return null;
  var fileNameString = contentDisposition.split(';').find(function (item) {
    return item.includes('filename=');
  });
  return (fileNameString || '').substring(9);
}

export default {
  methods: {
    exportExcelSync: function exportExcelSync(res, name) {
      var fileName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '文件.xlsx';

      if (!res.data || !res.headers['content-disposition']) {
        console.error('导出数据或名称存在异常 无法下载');
        this.$message.warning('系统错误!');
        return;
      }

      fileName = getFileNameByContentDisposition(res.headers['content-disposition']) || fileName;
      var blob = new Blob([res.data]);

      if ('msSaveOrOpenBlob' in navigator) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        var link = document.createElement('a');
        link.download = name;
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
    }
  }
};