import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
import beautyRequest from '@/utils/beautyRequest';
var BASE_URL = '/app-doc-center/v1/ckp-problem';
var DICT_URL = '/app-doc-center/v1/ckp/dic';
var STATISTIC_URL = '/app-doc-center/v1/ckp-statistic';
var NEWSELECT_URL = '/app/v1/';
/**
 * 获取已发布的必审清单列表
 * @returns 
 */

var getReleaseList = function getReleaseList(params) {
  return request.post("".concat(BASE_URL, "/ckp/release/list"), params);
};

var getDicContent = function getDicContent() {
  return request.get("/halo/api/app-doc-center/v1/sys/getRoleTagList"); // return request.get(`${NEWSELECT_URL}/dic/list-dicContent?dicCode=RoleTag`)
};

var getQuestionList = function getQuestionList(params, pageNo, pageSize) {
  return request.post("".concat(BASE_URL, "/relation/problem/list?pageNo=").concat(pageNo, "&pageSize=").concat(pageSize), params);
};

var getDict = function getDict() {
  return request.get("".concat(DICT_URL));
};

var exportExcel = function exportExcel(params) {
  return beautyRequest.post("".concat(BASE_URL, "/export/excel"), params, {
    responseType: 'blob'
  });
};
/** 审图统计类接口 */

/**
 * 审图要点统计信息
 * @param {Object} params 
 * @returns 
 */


var getCkpInfoList = function getCkpInfoList(params) {
  return request.post("".concat(STATISTIC_URL, "/ckp/info/list"), params);
};
/**
 * 审图要点项目统计信息
 * @param {Object} params 
 * @returns 
 */


var getPrjInfoList = function getPrjInfoList(params) {
  return request.post("".concat(STATISTIC_URL, "/prj/info/list"), params);
};
/**
 * 获得地区列表
 * @returns 
 */
// const getRegion = () => {
//     return request.get(`${STATISTIC_URL}/region/list`)
// }


var exportStatisticExcel = function exportStatisticExcel(params) {
  return beautyRequest.post("".concat(STATISTIC_URL, "/export/excel"), params, {
    responseType: 'blob'
  });
};

export default {
  getReleaseList: getReleaseList,
  getDicContent: getDicContent,
  getQuestionList: getQuestionList,
  getDict: getDict,
  // getRegion,
  getCkpInfoList: getCkpInfoList,
  getPrjInfoList: getPrjInfoList,
  exportExcel: exportExcel,
  exportStatisticExcel: exportStatisticExcel
};